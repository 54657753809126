import React from 'react';
import { ReactComponent as IconFacebook } from './assets/icons/facebook.svg';
import { ReactComponent as IconTwitter } from './assets/icons/twitter.svg';
import { ReactComponent as IconGithub } from './assets/icons/github.svg';
import {ReactComponent as IconLinkedin} from './assets/icons/linkedin.svg';
import "./App.css";

class App extends React.Component {
  render = () => {
    return (
      <div className="card">
        <div className="header">
          <div className="logo">
            <a href="/">Sualeh Muhammad</a>
          </div>
          <div className="social">
            <a href="https://www.facebook.com/Sualeh543" title="Facebook" target="_blank" rel="noopener noreferrer">
              <IconFacebook className="icon" />
            </a>
            <a href="https://www.linkedin.com/in/sualeh-farooq/" title="Twitter" target="_blank" rel="noopener noreferrer">
              <IconLinkedin className="icon" />
            </a>
            <a href="http://github.com/sualeh-farooq/" title="GitHub" target="_blank" rel="noopener noreferrer">
              <IconGithub className="icon" />
            </a>
          </div>
        </div>
        <div className="content">
          <div className="title-holder">
            <h1>Building Something Special: Exciting Updates Ahead!.</h1>
            <p>My site is getting a fresh look to better highlight my most exciting projects.  Keep an eye out for updates on my latest work and innovations. <br /> Stay tuned to see how I can bring your ideas to life.</p>
          </div>
          <a href="mailto:sualehfarooq65@gmail.com">
            <div className="cta">Want to get in touch? Drop me an email.</div>
          </a>
        </div>
        
      </div>
    );
  }
}

export default App;